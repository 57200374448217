//import { h, resolveComponent } from 'vue'
import { createRouter, createWebHashHistory } from 'vue-router'
//import DefaultLayout from '@/layouts/DefaultLayout'
//import { authGuard } from '../_helpers/auth.guard';
//import AuthLayout from "@/layouts/AuthLayout.vue";

const routes = [
  {
    path: '/forgotpassword',
    name: 'ForgotPasword',
    component: () =>
      import(/* webpackChunkName: "password" */ '@/views/ForgotPassword.vue'),
  },
  {
    path: '/newpassword/:token',
    name: 'NewPasword',
    component: () =>
      import(/* webpackChunkName: "password" */ '@/views/newpassword.vue'),
  },
  {
    path: '/',
    redirect: 'login',
    component: () =>
      import(/* webpackChunkName: "login" */ '@/views/login.vue'),
    children: [
      {
        path: '/login',
        name: 'login',
        component: () =>
          import(/* webpackChunkName: "demo" */ '@/views/login.vue'),
      },
    ],
    /*beforeEnter: ifNotAuthenticated*/
  },
  {
    path: '/',
    name: 'Home',
    component: () => import('@/layouts/DefaultLayout.vue'),
    children: [
      {
        path: '/home',
        name: 'Home',
        props: {isPremium: true},
        //beforeEnter: authGuard,
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "home" */ '@/views/home.vue'),
      },

      //{
      //  path: '/login',
      //  name: 'Login',
      //  component: () => import('@/views/login.vue'),
      //},
      //{
      //  path: '/forgotpassword',
      //  name: 'ForgotPasword',
      //  component: () => import('@/views/ForgotPassword.vue'),
      //},
      {
        path: '/sales-products',
        name: 'Products and sells',
        component: () =>
          import(
            /* webpackChunkName: "products" */ '@/views/Sells_Products/products-sells.vue'
          ),
      },
      {
        path: '/clientes',
        name: 'Clientes',
        //beforeEnter: authGuard,
        component: () =>
          import(/* webpackChunkName: "clients" */ '@/views/clientes.vue'),
      },
      {
        path: '/eventos',
        name: 'Eventos',
        //beforeEnter: authGuard,
        component: () =>
          import(/* webpackChunkName: "events" */ '@/views/eventos.vue'),
      },
      {
        path: '/import',
        name: 'Import',
        //props:{isAdmin:isAdmin},
        //beforeEnter: authGuard,
        component: () => import('@/views/import.vue'),
      },
      {
        path: '/import/complete',
        name: 'Import Complete',
       // props:{isAdmin:isAdmin},
        //beforeEnter: authGuard,
        component: () => import('@/views/data-import-bypass.vue'),
      },

      {
        path: '/import/dataprocessing',
        name: 'pipelinestatus',
        //props:{isAdmin:isAdmin},
        //beforeEnter: authGuard,
        component: () => import('@/views/dataanalysis/dataprocessing.vue'),
      },
      {
        path: '/conflict-import',
        name: 'Conflict Import',
        component: () => import('@/views/dataanalysis/conflict-import.vue'),
      },
      {
        path: '/perfil',
        name: 'Perfil',
        //beforeEnter: authGuard,
        component: () =>
          import(/* webpackChunkName: "profile" */ '@/views/perfil.vue'),
      },
      {
        path: '/import_source',
        name: 'Data Source Import',
        component: () =>
          import(
            /* webpackChunkName: "import" */ '@/views/data-source-import.vue'
          ),
      },
      {
        path: '/user_management',
        name: 'User Management',
        component: () =>
          import(/* webpackChunkName: "user" */ '@/views/user-management.vue'),
      },
      {
        path: 'accountmanager/adduser',
        name: 'Add User',
        component: () =>
          import(
            /* webpackChunkName: "user" */ '@/views/accountmanager/pages/add.vue'
          ),
      },
      {
        path: 'accountmanager/edituser/:id',
        name: 'Edit User',
        component: () =>
          import(
            /* webpackChunkName: "user" */ '@/views/accountmanager/pages/edit.vue'
          ),
      },
      {
        path: '/plans_billing',
        name: 'Plans and Billing',
        component: () =>
          import(/* webpackChunkName: "billing" */ '@/views/plans_billing.vue'),
      },
      {
        path: '/single_view/:id',
        name: 'Single Customer View',
        component: () =>
          import(
            /* webpackChunkName: "customer" */ '@/views/view360/pages/single_customer_view.vue'
          ),
      },
      {
        path: '/search_customer',
        name: 'Search Customer',
        component: () =>
          import(
            /* webpackChunkName: "customer" */ '@/views/view360/pages/search_customer.vue'
          ),
      },
      {
        path: '/search_customer_omnichannelCJ',
        name: 'Search Customer Journey',
        component: () =>
          import(
            /* webpackChunkName: "customer" */ '@/views/omnichannelCJ/SearchOmnichannelCJ.vue'
          ),
      },
      {
        path: '/faqs',
        name: 'Faqs',
        component: () =>
          import(/* webpackChunkName: "faq" */ '@/views/faqs.vue'),
      },
      {
        path: 'accountmanager/subscriptionpreferences',
        name: 'Subscription Preferences',
        component: () =>
          import('@/views/accountmanager/pages/subscription-preferences.vue'),
      },
      {
        path: '/support',
        name: 'Support',
        component: () =>
          import(/* webpackChunkName: "support" */ '@/views/support.vue'),
      },
      {
        path: '/api',
        name: 'Api',
        component: () =>
          import(/* webpackChunkName: "api" */ '@/views/swagger.vue'),
      },
      {
        path: '/omnichannelCJ/:id',
        name: 'Omnichannel Customer Journey Map',
        component: () =>
          import(/* webpackChunkName: "api" */ '@/views/omnichannelCJ/OmnichannelCJ.vue'),
      },
      {
        path: '/PrivacyPolicy',
        name: 'Privacy Policy',
        component: () =>
          import(/* webpackChunkName: "api" */ '@/views/PrivacyPolicyForm.vue'),
      },
      {
        path: '/overview-segments',
        name: 'OverviewSegments',
        component: () =>
          import(/* webpackChunkName: "api" */ '@/views/Segments/Overview/OverviewSegments.vue'),
      },
      {
        path: '/ProductsSegments',
        name: 'Products Segments',
        component: () =>
          import(/* webpackChunkName: "api" */ '@/views/Segments/Products/ProductsSegments.vue'),
      },
      {
        path: '/nbo',
        name: 'NBO',
        component: () =>
          import(/* webpackChunkName: "api" */ '@/views/MachineLearning/nbo/nbo.vue'),
      },
      {
        path: '/nbo-analysis',
        name: 'NBO Analysis',
        component: () =>
          import(/* webpackChunkName: "api" */ '@/views/MachineLearning/nbo-analysis/nboAnalysis.vue'),
      },
      {
        path: '/customer-journey-segments',
        name: 'Customer Journey Segments',
        component: () =>
          import(/* webpackChunkName: "api" */ '@/views/Segments/CustomerJourney/customerJourneySegments.vue'),
      },
      {
        path: '/api/wikipt',
        name: 'Como utilizar a Api',
        component: () =>
          import('@/views/api/wikipt.vue'),
      }, 
      {
        path: '/api/wikien',
        name: 'How to use Api',
        component: () =>
          import('@/views/api/wikien.vue'),
      },       
      /*{
        path: '/TestePieChart',
        name: 'TestePieChart',
        component: () => import('@/views/view360/pages/SingleCustomerPieChart.vue'),
      },*/

    ],
    /*beforeEnter: ifAuthenticated*/
  },
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})
//const userData = JSON.parse(localStorage.getItem('userData'));

router.beforeResolve(to=>{
  const userData = JSON.parse(localStorage.getItem('userData'));

  const isAdmin = userData==null || userData.idProfile==1; // verify if idProfile=1 it signify that is Admin
//console.log("route isAdmin",isAdmin);

// if user isn't type Admin
if(!isAdmin)
{
  //console.log(' beforeResolve não e admin');    
//console.log("to=",to);
if(to.name=='Import' || to.name=='Import Complete' || to.name=='pipelinestatus' 
   || to.name=='Data Source Import' || to.name=='Api' || to.name=='Como utilizar a Api'
   || to.name=='How to use Api')
  {
    //console.log('is Import')
    router.push('Home') 
  }
  
  /*router.replace({path:'/import', component:() =>
  import('@/views/home.vue')});*/
  
  /*router.removeRoute('Import');
  router.removeRoute('Import Complete');
  router.removeRoute('pipelinestatus');
  
  //router.removeRoute('Data Source Import');
  router.replace({path:'/api', component:() =>
  import('@/views/home.vue')})

  //router.removeRoute('Api');
  router.replace({path:'/api', component:() =>
  import('@/views/home.vue')})
  router.removeRoute('Como utilizar a Api');
  router.removeRoute('How to use Api');*/
}
})
router.beforeEach((to, from, next) => {
  const userData = JSON.parse(localStorage.getItem('userData'));

  const isAdmin = userData==null || userData.idProfile==1; // verify if idProfile=1 it signify that is Admin
//console.log("route isAdmin",isAdmin);

// if user isn't type Admin
if(!isAdmin)
{
  console.log(' não e admin');
  router.removeRoute('Import');
  router.removeRoute('Import Complete');
  router.removeRoute('pipelinestatus');
  router.removeRoute('Data Source Import');
  router.removeRoute('Api');
  router.removeRoute('Como utilizar a Api');
  router.removeRoute('How to use Api');
}
 /*const testeNotifications = localStorage.getItem('testeNotifications')
 console.log("testeNotifications  index=", JSON.stringify(testeNotifications));
 const teste = JSON.parse(JSON.stringify({"numero":3}));
 console.log("teste2", testeNotifications.numero);
 console.log("teste =>", teste);
 console.log("teste.numero=>", teste.numero);
 localStorage.removeItem('testeNotifications')
 if(testeNotifications == null){
  localStorage.setItem('testeNotifications', JSON.parse(JSON.stringify({numero:3})));
 }

 console.log("testeNotifications  index2=", localStorage.getItem('testeNotifications'));*/
  // trying to access a restricted page + not logged in
  // redirect to login page
  if(to.meta.extra){
    if(userData.rules){
      let rules = userData.rules.split(';');
      let i = rules.filter(n => n === to.meta.rule)
      if(!i){
        next({ path: '/home' })
      }
    }
    else{
      next({ path: '/home' })
    }


  }
  next();
  /*
  if (authRequired && !loggedIn) {
    next('/login');
  } else {
    next();
  }*/
});


export default router
